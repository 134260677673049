import React from 'react';
import 'twin.macro';
import { Link } from 'gatsby';

import useSiteMetaData from '~hooks/useSiteMetaData';

const Footer: React.FC = () => {
  const { siteMetadata } = useSiteMetaData();
  return (
    <footer tw="bg-gray-50 mt-auto">
      <div tw="container">
        <div tw="w-full flex flex-col items-center justify-between md:(flex-row)">
          <p tw="text-sm text-gray-700 my-6 text-center md:(text-left)">
            &copy;{new Date().getFullYear()} {siteMetadata.defaultTitle}
          </p>
          <div tw="grid gap-3 text-sm my-6 text-center md:(text-right grid-cols-2)">
            <Link tw="block text-rose underline hover:(text-rose-900 no-underline)" to="/about">
              About Mohawk
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
