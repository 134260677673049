import React from 'react';

const Close: React.FC = (props) => (
  <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="currentColor" d="M17.341 95.324L6.028 84.01 81.009 9.028l11.314 11.314z"></path>
    <path fill="currentColor" d="M7.346 20.342L18.659 9.028 93.641 84.01 82.327 95.323z"></path>
  </svg>
);

export default Close;
