import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import tw from 'twin.macro';
import { Dialog } from '@headlessui/react';

import Close from '~components/Svg/Close';
import Logo from '~components/Svg/Logo';

import Heading from '~components/Heading';
import { Transition } from '~components/Transition';

import NavLink, { IconNavLink } from './NavLink';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ open, onClose }) => {
  const initialFocus = React.useRef(null);
  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        onClose={onClose}
        initialFocus={initialFocus}
        tw="fixed max-h-screen inset-0 z-40 overflow-x-hidden overflow-y-auto grid lg:(grid-cols-3)"
      >
        <button
          css={[
            tw`flex flex-col space-y-1 items-center justify-center rounded-full p-2 h-16 w-16 absolute top-3 right-3 shadow-xl z-50`,
            tw`text-xs uppercase tracking-wide`,
            tw`transition-colors duration-100 ease`,
            tw`focus:(outline-none ring-4 ring-green)`,
            tw`bg-orange text-gray-50`,
            tw`hover:(bg-black text-gray-50)`
          ]}
          onClick={onClose}
        >
          <Close tw="w-4" />
          <span>
            Close<span tw="sr-only"> Menu</span>
          </span>
        </button>
        <Transition.Child
          as={Fragment}
          enter={tw`transform ease-out duration-300`}
          enterFrom={tw`-translate-y-full opacity-0`}
          enterTo={tw`translate-y-0 opacity-100`}
          leave={tw`transform ease-in duration-300`}
          leaveFrom={tw`translate-y-0 opacity-100`}
          leaveTo={tw`translate-y-full opacity-0`}
        >
          <div tw="py-12 bg-black flex flex-col justify-start items-start px-4 lg:(col-span-1 px-12 pt-24)">
            <Link to="/" ref={initialFocus} tw="mb-12 mx-auto lg:(mx-0)">
              <span tw="sr-only">Home</span>
              <Logo tw="max-w-full w-96 h-auto text-white" />
            </Link>
            <nav
              aria-label="Main Menu"
              tw="flex flex-col items-center w-full divide divide-y divide-gray-200 lg:(items-start w-auto)"
            >
              <NavLink to="/">Home</NavLink>
              <NavLink to="/intro">Introduction to the Plan</NavLink>
              <NavLink to="/vision">Vision, Mission &amp; Values</NavLink>
              <NavLink to="/foundations">Strong Foundations</NavLink>
              <NavLink to="/about">About Mohawk</NavLink>
            </nav>
          </div>
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter={tw`transform ease-out duration-300`}
          enterFrom={tw`translate-x-full`}
          enterTo={tw`translate-x-0`}
          leave={tw`transform ease-in duration-300`}
          leaveFrom={tw`translate-x-0`}
          leaveTo={tw`translate-x-full`}
        >
          <div tw="py-12 bg-white flex flex-col items-start px-4 lg:(col-span-2 px-12 pt-24)">
            <Heading level="h2">
              Aspirations, Strategic Directions &amp; Leadership Outcomes
            </Heading>
            <nav aria-label="Aspirations Menu" tw="flex flex-col">
              <IconNavLink to="/aspirations/truth-reconciliation">
                Meaningfully advance Truth and Reconciliation
              </IconNavLink>
              <IconNavLink to="/aspirations/future-ready">
                Enable Future Ready education, research, programs, services and experiences – for
                students, employees and our community
              </IconNavLink>
              <IconNavLink to="/aspirations/celebrate-every-person">
                Be a place that honours, values and celebrates the whole of every person
              </IconNavLink>
              <IconNavLink to="/aspirations/education">
                Ensure access to education and reduce barriers for all
              </IconNavLink>
              <IconNavLink to="/aspirations/workforce-development">
                Lead in workforce development
              </IconNavLink>
              <IconNavLink to="/aspirations/climate-change">
                Make a measurable impact on climate change
              </IconNavLink>
            </nav>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;
