import React from 'react';

const Burger: React.FC = (props) => (
  <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="#F17C14"
      d="M98 96V80H2v16h96zm0-36V44H2v16h96zM65.82 8C89.57 8 98 10.294 98 23.186V24H2V8.015h63.821V8z"
    ></path>
  </svg>
);

export default Burger;
