import React from 'react';
import tw from 'twin.macro';

import Burger from '~components/Svg/Burger';

import Modal from './Modal';

const Menu: React.FC = () => {
  const initialFocus = React.useRef(null);
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const open = () => setOpen(true);
  const close = () => setOpen(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <>
      <button
        css={[
          tw`flex flex-col space-y-1 items-center justify-center rounded-full p-2 h-16 w-16 fixed top-3 right-3 shadow-xl z-40`,
          tw`text-xs uppercase tracking-wide`,
          tw`transition-colors duration-100 ease`,
          tw`focus:(outline-none ring-4 ring-green)`,
          tw`bg-red text-gray-50`,
          tw`hover:(bg-rose text-gray-50)`
        ]}
        onClick={open}
      >
        <Burger tw="w-4" />
        <span>
          <span tw="sr-only">Open </span>Menu
        </span>
      </button>
      <Modal open={isOpen} onClose={close} />
    </>
  );
};

export default Menu;
