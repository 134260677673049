import React from 'react';
import 'twin.macro';

import Icon from '~components/Svg/Icon';

const StyledHeading: React.FC = ({ children, ...rest }) => (
  <h2 tw="flex items-center space-x-3 mt-6 mb-3 font-bold font-display text-2xl md:(text-3xl) lg:(text-5xl)" {...rest}>
    <Icon tw="w-auto h-8" />
    <span>{children}</span>
  </h2>
);

export default StyledHeading;
