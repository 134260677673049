import React from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';

import Icon from '~components/Svg/Icon';

interface IconNavLinkProps {
  to: string;
}

const NavLink = tw(
  Link
)`block text-white px-2 py-4 font-semibold text-lg bg-transparent transition-colors duration-100 ease md:(text-2xl) lg:(text-3xl) hover:(bg-rose) focus:(outline-none ring ring-red ring-opacity-60)`;

export const IconNavLink: React.FC<IconNavLinkProps> = ({ to, children }) => (
  <Link
    to={to}
    tw="block text-black flex items-start space-x-3 px-2 py-4 font-bold text-lg underline rounded-tr-4xl md:(text-xl) lg:(text-2xl) hover:(no-underline) focus:(outline-none ring ring-red ring-opacity-60)"
  >
    <Icon tw="w-8 h-auto mt-2 flex-shrink-0" />
    <span >{children}</span>
  </Link>
);

export default NavLink;
