import React from 'react';

const Icon: React.FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 50" fill="none" {...props}>
    <path fill="#760A30" d="M20.143 0H0v50h20.143V0z"></path>
    <path fill="#AF1232" d="M50.609 0H30.447v50H50.61V0z"></path>
    <path
      fill="#F17C14"
      d="M81 16.815C81 4.501 78.124.13 61.954.13h-1.021v49.777h20.05V16.815h.019z"
    ></path>
  </svg>
);

export default Icon;
